@import url(https://fonts.googleapis.com/css?family=Slabo+27px:400,700);
@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500,700);
@import url(https://fonts.googleapis.com/css?family=Material+Icons);
@import url(https://fonts.googleapis.com/css2?family=Karla:ital,wght@0,200..800;1,200..800&display=swap);
// @import url(https://fonts.googleapis.com/css?family=Karla);
@import "./variables";
@import "./mixins";
@import "../../node_modules/bootstrap/scss/bootstrap";
@import "./bootstrap_override.scss";
@import "./material_override.scss";
@import "./pager.scss";
@import "./builder.scss";

html, body {
  height: 100%;
  margin: 0;
}

body {
  font-family: $body-font-family;
}

a {
  color: $primary-hover;
}

.cdk-overlay-container {
  z-index: 2000;
}

.error {
  color: $reject-color;
  font-family: $error-font-family;
  font-size: 75%;
  line-height: 1.125;
}

.title-font {
  font-size: $title-font-size;
  font-weight: $title-font-weight;
}

.sub-title-font {
  font-size: $sub-title-font-size;
  color: inherit;
  font-weight: $sub-title-font-weight;
}

.primary-clr {
  color: $primary-color;
}

.secondary-clr {
  color: $secondary-color;
}

thead tr {
  text-align: left;
  background-color: $disabled-color;
  color: $selected-metal;
  th {
    font-weight: $sub-title-font-weight;    
  }

  .font-weight-bold {
    font-weight: bold;
    background-color: black;
  }
}

table.order-listing{
  thead tr{
    th{
      background-color: $disabled-color;
      color: $selected-metal;
    }
  }  
}

tbody tr:hover {
  background-color: $primary-color !important;
  cursor: pointer;
  color: $selected-metal;
}

.table-bordered th,
.table-bordered td {
  border: 0px;
}

.modal-dialog {
  width: 50rem;
  // margin-top: 15rem;
  margin: 0 !important;
}

.fa.fa-usd {
  position: absolute;
  margin-top: 4px;
}

.fa.fa-trash {
  color: $reject-color;
  cursor: pointer;
}

.fa-download {
  color: $secondary-font-color;
}

.star-style {
  font-size: $star-font-size;
  color: $secondary-color;
}
.column1 {
  padding-left: 0px !important;
  width: 32.5%;
}
.column2 {
  padding-left: 29px !important;
  width: 18%;
}
.column3 {
  padding-left: 19px !important;
  width: 17%;
}
.column4 {
  padding-left: 12px !important;
}

.msgFormat {
  white-space: pre-line;
  word-break: break-word;
}

.breakWord {
  white-space: pre-line;
  word-break: break-word;
}

.pos-right {
  position: fixed;
  bottom: 4.4rem;
  right: 15px;
  background-color: #363d44;
  border-radius: 2px;
  color: $background-color;
  padding: 3px 7px;
}

.scrollTop {
  background-color: $secondary-color !important;
  z-index: 1000;
  position: fixed !important;
  bottom: 7rem;
  right: 0.99rem;
}
html {
  scroll-behavior: smooth;
}
.secondary-backgroundclr {
  background-color: $secondary-color;
  color: $background-color;
  font-weight: bolder;
}
/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
.selHideArrowDown .mat-select-arrow {
  color: $background-color !important;
  cursor: default;
}
.star-color {
  color: $secondary-color;
}
.toggle-green {
  color: #0f9d58;
}
.toggle-red {
  color: rgb(212, 63, 58);
}

.toggle-orange {
  color: #f35f08;
}

/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
.mat-select-filter-input {
  padding: 2px 16px !important;
  margin-top: 8px !important;
}

.filter-clear {
  position: absolute !important;
  top: -5px;
  right: 2px;
}

.mat-mdc-menu-content .filter-clear {
  top: 2px;
  right: 8px;
}

.star-size {
  font-size: 1.15em;
  line-height: 0.75em;
  vertical-align: -15%;
}

.interMsgColor {
  background-color: #f6cf973b;
  padding: 3px 9px;
  border-radius: 2px;
  word-break: break-word;
}

.cursorPointer {
  cursor: pointer;
}

.activeColor {
  color: #357ebd !important;
  text-decoration: underline;
  cursor: pointer;
}

.fa-play,
.fa-pause {
  color: $primary-color;
}
.hideScrollbar {
  overflow: hidden !important;
  padding: 0px !important;
  margin: 0px !important;
}
.toggleTextSize {
  font-size: 14px;
}

.modal-header {
  cursor: move;
}
.AbsFontSize {
  font-size: 12px;
}
.AbsFontSizeOE {
  font-size: 14px;
}

.fontColor {
  color: $accept-hover;
}

.order-tags-pos {
  color: $primary-color;
}

tbody tr:hover .order-tags-pos {
  color: white !important;
}

.mat-mdc-chip:hover {
  cursor: pointer;
}

.tag-list {
  min-height: 150px;
  max-height: 200px;
  overflow-y: auto;
}

.associated-tag-list {
  max-height: 150px;
  overflow-y: auto;
}

input.mat-mdc-menu-item {
  height: 24px;
}

.wrapInSingleLine {
  white-space: nowrap;
}

hr.hr-text {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.hr-text {
  line-height: 1em;
  position: relative;
  outline: 0;
  border: 0;
  color: black;
  text-align: center;
  height: 1.5em;
  &:before {
    content: "";
    background: black;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    height: 1px;
  }
  &:after {
    content: attr(data-content);
    position: relative;
    display: inline-block;
    padding: 0 0.5em;
    line-height: 1.5em;
    color: black;
    background-color: white;
  }
}

.three-dot-menu {
  height: 30px !important;
  line-height: 30px !important;
  width: 30px !important;
}

.fa.fa-file {
  cursor: pointer;
}
.fa.fa-file-excel-o {
  cursor: pointer;
}
.displayInline {
  float: right;
  margin-top: -3%;
  position: relative;
  top: -6px;
  cursor: pointer;
}

tbody.owl-dt-calendar-body tr:hover {
  background-color: transparent !important;
}

.orderSearchlink {
  text-decoration: underline;
  cursor: pointer;
  color: $primary-color;
}

tbody tr:hover {
  .orderSearchlink, .inActiveCSS, .activeCSS{
    color: white;
  }
}

.linkColor {
  color: currentColor !important;
}

.profileIconColor {
  color: $primary-color;
}
tbody tr:hover .fa.fa-user {
  color: white;
}
tbody tr:hover .fa.fa-stack-1x {
  color: black !important;
}

.fa-stack-1x {
  line-height: 30px;
  font-size: 9px;
}

.fa-stack-2x {
  font-size: 1.5em;
}

.color-select {
  border: 2px #212529 solid;
}

.color-picker {
  width: 30px;
  height: 30px;
  margin: 2px;
  border-radius: 50%;
}

.color-ring {
  border: 2px solid black;
}

table,
ul.sidebar {
  font-size: $table-sidebar-font-size;
}

.table th,
.table td {
  padding: 0.5rem;
}

.textRight {
  text-align: right;
}

.textLeft {
  text-align: left;
}

.addMarginLeft {
  margin-left: 250px;
  @media screen and (max-width: 2000px) {
    table.order-listing {
      thead {
        th {
          top: 63px;
        }
      }
    }
    @media screen and (max-width: 1200px) {
      .sub-title-font {
        white-space: nowrap;
      }
      // table {
      //   display: block;
      //   width: 100%;
      //   overflow-x: auto;
      //   -webkit-overflow-scrolling: touch;
      //   -ms-overflow-style: -ms-autohiding-scrollbar;
      // }     
      .col-lg-4 {
        flex: 0 0 45%;
        max-width: 45%;
      }
      .col-lg-3 {
        flex: 0 0 5%;
        max-width: 5%;
      }
      .col-lg-5 {
        flex: 0 0 50%;
        max-width: 50%;
      }
      @media screen and (max-width: 992px) {
        .sub-title-font {
          font-size: 1rem;
        }
        .col-lg-4 {
          flex: 0 0 45%;
          max-width: 45%;
        }
        .col-lg-3 {
          flex: 0 0 5%;
          max-width: 5%;
        }
        .col-lg-5 {
          flex: 0 0 50%;
          max-width: 50%;
        }
        @media screen and (max-width: 900px) {
          .col-lg-4 {
            flex: 0 0 40%;
            max-width: 40%;
          }
          @media screen and (max-width: 784px) {
            .col-sm-5 {
              flex: 0 0 45%;
              max-width: 45%;
            }
            .col-sm-1 {
              display: none;
            }
            .col-lg-5 {
              flex: 0 0 55%;
              max-width: 55%;
            }
            @media screen and (max-width: 733px) {
              table.order-listing {
                thead {
                  th {
                    top: 83px;
                  }
                }
              }
              margin-top: 12% !important;
              margin-bottom: 16% !important;
              .textRight,
              .textLeft {
                text-align: center;
              }
              .col-sm-5,
              .col-sm-6,
              .col-sm-1 {
                flex: 0 0 100%;
                max-width: 100%;
              }
              @media screen and (max-width: 575px) {
                margin-top: 20% !important;
                margin-bottom: 23.5% !important;               
                  @media screen and (max-width: 480px) {
                      display: none;
                  }
              }
            }
          }
        }
      }
    }
  }
}

.removeMarginLeft {
  margin-left: 0px;
  .sub-title-font {
    white-space: nowrap;
  }
  @media screen and (max-width: 2000px) {
    table.order-listing {
      thead {
        th {
          top: 63px;
        }
      }
    }
    @media screen and (max-width: 992px) {
      .sub-title-font {
        font-size: 1rem;
      }
      .col-md-4 {
        flex: 0 0 30%;
        max-width: 30%;
      }
      .col-md-3 {
        flex: 0 0 35%;
        max-width: 35%;
      }
      .col-md-5 {
        flex: 0 0 35%;
        max-width: 35%;
      }
      @media screen and (max-width: 870px) {
        // table {
        //   display: block;
        //   width: 100%;
        //   overflow-x: auto;
        //   -webkit-overflow-scrolling: touch;
        //   -ms-overflow-style: -ms-autohiding-scrollbar;
        // }
        @media screen and (max-width: 768px) {
          .offset-md-1 {
            margin-left: 0;
          }
          .col-md-10 {
            flex: 0 0 100%;
            max-width: 100%;
          }
          @media screen and (max-width: 696px) {
            table.order-listing {
              thead {
                th {
                  top: 83px;
                }
              }
            }
            margin-top: 18% !important;
            margin-bottom: 15% !important;
            .textRight,
            .textLeft {
              text-align: center;
            }
            .col-md-4 {
              flex: 0 0 100%;
              max-width: 100%;
            }
            .col-md-3 {
              flex: 0 0 100%;
              max-width: 100%;
            }
            .col-md-5 {
              flex: 0 0 100%;
              max-width: 100%;
            }
            .welcome-home-dropdown {
              right: inherit !important;
              top: 2rem !important;
            }
            @media screen and (max-width: 321px) {
              margin-top: 25% !important;
              margin-bottom: 36% !important;
            }
          }
        }
      }
    }
  }
}

.mdc-evolution-chip-set .mdc-evolution-chip {
  margin: 1.5px !important;
}

.mat-mdc-standard-chip {
  padding: 7px !important;
  min-height: 26px !important;
}

.eyeColor {
  color: #428bca;
}
tbody tr:hover .eyeColor {
  color: white !important;
}

.editIconStyle {
  color: $primary-color;
  font-size: 18px;
}
tbody tr:hover .editIconStyle {
  color: white !important;
  padding-left: 17px !important;
  padding-top: 2px !important;
}

.fontweightBold {
  font-weight: bold;
  text-transform: capitalize;
}

.textCenter {
  text-align: center;
}
.addTagColor {
  background-color: #55c5668f !important;
}
.plusIconSize {
  font-size: 10px !important;
  padding-top: 1px !important;
}

.fullHeight {
  height: 100%;
}

.bill-bg {
  background-color: #ebf5fd;
  margin-bottom: 4px;
  margin-top: 4px;
}

table.order-listing {
  thead {
    th {
      position: sticky;
      z-index: 1;
    }
  }
}



@media only screen and (max-width: 898px) and (min-width: 882px)  {
  .addMarginLeft {
    table.order-listing {
      thead {
        th {
          top: 63px !important;
        }
      }
    }
  }  
}
.checkIcon {
  font-size: 0px;
}

.removeMargins{
  margin-right: 0px;
  margin-left: 0px;
}

.centerButton{
  display: flex;
  align-items: center;
  justify-content: center;
}

.pac-container{
  z-index: 10000;
}

.iconPaddingLeft{
  padding-left: 0.1rem;
}

.trachPos {
  height: 1.6rem !important;
}
.selecttrachPos {
  color: red;
}

.logStyle{
  float: right;
  font-size: 16px;
}

.clipIconSize{
  font-size: 12px;
  display: none;
}

tbody tr:hover .clipIconSize {
  display: inline;
  position:absolute;
  margin-left: 3px;
  margin-top: 6px;
}

.usdFontFamily{
  font-family: $body-font-family;
}

.showAllBtn{
  width: 85% !important;
}

.report-section-label{
  font-size: larger;
  font-weight: 600;
}

/* Pippin Brand Variables by greeg*/

:root {
  --pippin-color-black: #10162B;
  --pippin-color-black-dark: #060B1D;
  --pippin-color-black-light: #2E2E57;
  --pippin-color-gray: #dee2e6;
  --pippin-color-grayer: #bec0c2;
  --pippin-color-gray-dark: #495057;
  --pippin-color-gray-darker: #3d4248;
  --pippin-color-gray-light: #E9ECEF;
  --pippin-color-gray-lightest: #f7f7f7;
  --pippin-color-blue: #76D0F4;
  --pippin-color-blue-dark: #428BCA;
  --pippin-color-blue-darker: #267bc4;
  --pippin-color-blue-light: #E5F5FD;
  --pippin-color-red: #EE4751;
  --pippin-color-red-dark: #C13941;
  --pippin-color-red-light: #FAADB2;
  --pippin-color-status-success: #45B25D;
  --pippin-color-status-warning: #fde6a0;
  --pippin-color-status-danger: #f16161;
   --pippin-color-status-info: #FFE79E;
}

/* Material Variable Overides */
:root {
  --blue: var(--pippin-color-blue-dark);
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: var(--pippin-color-gray);
  --gray-dark: var(--pippin-color-gray-dark);
  --primary: var(--pippin-color-blue);
  --secondary: var(--pippin-color-gray);
  --success: var(--pippin-color-status-success);
  --info: var(--pippin-color-status-info);
  --warning: var(--pippin-color-status-warning);
  --danger: var(--pippin-color-status-danger);
  --light: var(--pippin-color-gray-light);
  --dark: var(--pippin-color-gray-dark);
}

/* Forms */

/* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version.*/
.mat-mdc-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: var(--pippin-color-blue-dark);
}
/* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version.*/
.mat-mdc-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: var(--pippin-color-blue);
  opacity: 1;
}

/* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
.mat-mdc-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  background-color: var(--pippin-color-blue-light);
  border-color: var(--pippin-color-blue-dark);
}

.mat-mdc-radio-button.mat-accent .mat-radio-inner-circle, .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
   background-color: var(--pippin-color-blue-dark);
}

/* Helpers */
.bgColor {
  background-color: var(--pippin-color-gray-lightest);
}

/* Tables */

table.order-listing thead tr th,
thead tr {
  color: var(--pippin-color-black);
  background-color: var(--pippin-color-gray-light);
  border-color: var(--pippin-color-gray);
}

thead tr .font-weight-bold {
  font-weight: bold;
  background-color: var(--pippin-color-gray);
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: var(--pippin-color-gray-lightest);
}

.due_today, .dueDate {
  background-color: var(--pippin-color-status-warning) !important;
}

.past_due, .PastDate {
  background-color: var(--pippin-color-status-danger) !important;
}

tbody tr:hover {
  background-color: var(--pippin-color-blue) !important;
  cursor: pointer;
  color: white;
}

.received[_ngcontent-c17] {
  color: #000;
  background-color: rgba(118,208,244,.35);
  transition: background-color 1s ease-out;
}

/*Buttons */
  
.btn {
  border-radius: 15px;
  text-transform: capitalize !important;
}
.btn-primary {
  font-size: 0.88rem;
  font-weight: bold;
  text-transform: capitalize ;
  color: white;
  background-color: var(--pippin-color-blue-dark);
  border-color: var(--pippin-color-blue-dark);
  border-radius: 15px;
}

.btn-primary:hover {
  font-size: 0.88rem;
  font-weight: bold;
  text-transform: capitalize;
  color: white;
  background-color: var(--pippin-color-blue-darker);
  border-color: var(--pippin-color-blue-dark);
  border-radius: 15px;
}


.btn-default {
  font-family: "Roboto";
  font-size: 0.88rem;
  font-weight: bold;
  text-transform: capitalize ;
  color: var(--pippin-color-black);
  background-color: var(--pippin-color-gray) !important;
  border-color: var(--pippin-color-gray)!important;
  border-radius: 15px;
}

.btn-default:hover {
  background-color: var(--pippin-color-grayer) !important;
  border-color: var(--pippin-color-grayer) !important ;
  color: var(--pippin-color-black) !important
}
.btn-default:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(152, 154, 155, 0.527) !important;
}

.sub-title-font {
     letter-spacing: -.06em;
}

#ordDetBtnSendMsg {
    background-color: var(--pippin-color-gray-dark) !important;
    color: #FFF ;
}

#ordDetBtnSendMsg:hover {
    background-color: var(--pippin-color-gray-darker) !important;
    color: #FFF !important;
}

#ordDetBtnSendMsg:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(51, 51, 51, 0.445) !important;
}

/* Badges & Tags */

.badge {
  background-color: var(--pippin-color-blue-dark);
  border-color: var(--pippin-color-blue-dark);
  border-radius: 1rem;
  color: #FFF;
  display: inline-block;
  padding: 0.25em 0.6em 0.25em 0.6em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
}

.tagsColor .badge {
  background-color: rgba(255,255,255,.2);
  color: inherit;
}

.addTagColor {
  background-color: #55c5668f !important;
}

/* Sidebar */
// .sidebar {
//   border-right: 1px solid var(--pippin-color-gray-light);
// }

.sidebar .badge {
  background-color: var(--pippin-color-red) ;
}
.order-btn {
   margin-top: .5rem !important;
      background-color: var(--pippin-color-red) !important ;
  color: #fff!important;
  text-shadow: 1px 1px 1px grey;
  border-radius: 10px !important;
  border-color: var(--pippin-color-red) !important ;
}

.order-btn:hover {
  background-color: var(--pippin-color-red-dark);
  border-color: var(--pippin-color-red-dark);
}

ul.sidebar li a.active,
ul.sidebar li a.active:hover{
  background-color: var(--pippin-color-black) !important;
  color: #fff;
  border: 1px solid var(--pippin-color-black) !important;
}

ul.sidebar li a:hover {
  background-color: var(--pippin-color-black) !important;
  color: inherit;
  cursor: pointer;
  text-decoration: none;
     color: #fff;
}

ul.sidebar li a:hover .smallSizeFont {
  color: rgba(255,255,255,.5);
}

.mat-mdc-raised-button {
  border-radius: 15px !important;
}

.pos-sheet{
  position: absolute !important;
  width: auto;
  right: 25px;
  bottom: 125px;
  // background-color: #10162B;
  color: #FFFFFF;
  padding: 16px !important;
  .mat-bottom-sheet-container {
    background: #10162B;
    padding: 20px;
    color: #FFFFFF;
  }
}

.info-tooltip{
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 15px;
  gap: 10px;
  background: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.87);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  width: 100%!important;
  color:rgba(0, 0, 0, 0.87) !important;
  font-weight: 800 !important;
  font-size: 14px !important;
  max-width: unset !important;
}

.sp-docs-display {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  .file-label {
    cursor: move;
  }

  .fa.fa-file {
    cursor: move;
  } 
  
  .cdk-drop-list-dragging {
    .file-label{
      color: transparent;
      border: 2px dashed transparent;
      transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
      .fa.fa-file{
        color: transparent;
      }
      .del-doc{
        visibility: hidden !important;
      }
    }
  }
}

.file-label.cdk-drag.cdk-drag-preview{
  cursor: move !important;
  color: #428bca;
  border: 2px dashed #267bc4 !important;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
              0 8px 10px 1px rgba(0, 0, 0, 0.14),
              0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.chkl-expd-area{
  width: 100%;
  border-radius: 4px;
  background: #ECF3FF;
  display: flex;
  flex-direction: row;
  padding: 5px 7px;
  align-items: flex-start;
  gap: 10px;
  margin: 8px 0;
  font-size: 9px;
}

.chkexpfnt-normal{
  color: #7A7A7A;
  font-family: Roboto;
  font-size: 9px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.45px;
}

.chkexpfnt-bold{
  color: #7A7A7A;
  font-family: Roboto;
  font-size: 9px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.45px;
}

.chkexpbtn{
  display: flex;
  padding: 2px 5px 3px 5px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 7px;
  border: 1px solid #E6E6E6;
  background: #FFF;
  color: #7A7A7A;
  font-family: Roboto;
  font-size: 9px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.45px;
  cursor: pointer;
}

.chkexplink{
  color:  #4FB2D9 !important;
  font-family: Roboto;
  font-size: 9px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.45px;
  text-decoration-line: underline !important;
  cursor: pointer;
}

.chkexpcnt{
  border-radius: 8px;
  background: #7A7A7A;
  color: #FFF;
  font-family: Roboto;
  font-size: 9px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.45px;
  float: right;
  width: 16px;
  height: 16px;
  text-align: center;
  padding-top: 3px;
}

.draggable{
  cursor: move;
}

.align-center{
  align-items: center;
}

.custom-chip-icon {
  padding-top: 3px;
  margin-left: auto; /* Aligns the icon to the right */
}

.rb-section-loading {
  position: relative;
  overflow: hidden;
  background: #f0f0f0;
  pointer-events: none;
  user-select: none;
}

.rb-section-loading::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(200, 200, 200, 0.6);  
  animation: shimmer-opacity 1.5s infinite alternate;
  z-index: 10;
}

@keyframes shimmer-opacity {
  0% {
    background: rgba(200, 200, 200, 0.7);  
  }
  100% {
    background: rgba(200, 200, 200, 0.3); 
  }
}
